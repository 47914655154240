import React, { useEffect, useMemo, useState } from "react";

import { Modal } from "@material-ui/core";
import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getUserData, setPaymentDiscount, setUpgradeModal } from "store/features/Auth/authSlice";
import { getSubscriptionInfo, getUserInfo } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";

import { GLOBAL_CONSTANTS } from "../../../../constants";
import { useUpgradeForm } from "../../../../hooks/useUpgradeForm";
import StudentForm from "../../../Course/StudentForm";
import UpgradeSubscription from "../../../MyAccount/UpgradeSubscription";
import BaseLayout from "../../BaseLayout";

const StudentModal = ({ modal, closeModal }) => (
	<Modal
		style={{
			alignItems: "center",
			justifyContent: "center",
			display: "flex"
		}}
		disableEnforceFocus
		disableAutoFocus
		open={!!modal}
	>
		<StudentForm closeModal={closeModal} />
	</Modal>
);
const GlobalWrapper = ({ children }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useSearchParams();
	const shouldOpenUpgradeModal = params.get("upgrade");
	const {
		user,
		paymentDiscount,
		isLoggedIn,
		inactiveSubscription: { isRenewEnabled },
		upgradeModal
	} = useAppSelector(getFullState);
	const showSchoolModal = GLOBAL_CONSTANTS.TOGGLE_SCHOOL_MODAL;
	const [studentModal, setStudentModal] = useState(user && showSchoolModal && (!user.schoolId || !user.hasTakenIntro));
	const isTrial = useMemo(() => getCurrentSubscription(user?.subscriptions).type?.isTrial, [user]);
	const onUpgradeSubmit = () => {
		dispatch(setUpgradeModal(false));
		dispatch(getUserData());
		dispatch(getUserInfo());
		dispatch(setPaymentDiscount(null));
		dispatch(getSubscriptionInfo());
		history.push(`${routes.course.getPath()}?refetch=true`);
	};
	const { closeModal: closeUpgradeModel, ...upgradeFormProps } = useUpgradeForm({
		onSubmit: onUpgradeSubmit,
		dispatch,
		isRenewSubscription: isRenewEnabled
	});

	useEffect(() => {
		if (user && showSchoolModal) {
			setStudentModal(!user.schoolId || !!paymentDiscount || !user.hasTakenIntro);
		}
	}, [showSchoolModal, user, paymentDiscount]);

	useEffect(() => {
		if (Boolean(shouldOpenUpgradeModal) && isTrial) {
			dispatch(setUpgradeModal(true));
		}
	}, [shouldOpenUpgradeModal, isTrial]);

	const closeModal = () => setStudentModal(false);
	if (isLoggedIn) {
		return (
			<BaseLayout>
				{children}
				<StudentModal modal={studentModal} closeModal={closeModal} />
				<Modal open={upgradeModal!} onClose={closeUpgradeModel}>
					<UpgradeSubscription {...upgradeFormProps} closeModal={closeUpgradeModel} />
				</Modal>
			</BaseLayout>
		);
	}
	return (
		<>
			{children}
			<StudentModal modal={studentModal} closeModal={closeModal} />
		</>
	);
};

export default GlobalWrapper;
