import React, { memo, useEffect, useMemo } from "react";

import { IconButton, Tooltip } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";

import { TopNav as SharedTopNav } from "@remar/shared/dist/layouts/BaseLayout";
import { NotificationDeliveryMethodEnum } from "@remar/shared/dist/models";
import useFirebaseNotifications from "@remar/shared/dist/services/useFirebaseNotifications";
import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, logout } from "store/features/Auth/authSlice";
import {
	getFullState as getFullNotifications,
	getUserNotifications,
	setBannerData
} from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

import Banner from "./Banner";
import BellNotifications from "./BellNotifications";

const TopNav = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const {
		user: userInfo,
		subscription: { isTrial }
	} = useAppSelector(getFullState);
	const { onDeleteFcmToken } = useFirebaseNotifications();
	const { bannerNotifications, bannerData } = useAppSelector(getFullNotifications);

	const analytics = useAnalyticsEventTracker("User");

	useEffect(() => {
		dispatch(
			getUserNotifications({
				filters: {
					"notification.deliveryMethod": NotificationDeliveryMethodEnum.Socket
				},
				include: ["notification.coupon.courses", "notification.coupon.products"]
			})
		);
	}, [dispatch]);

	const BannerNotifications = useMemo(() => bannerNotifications?.items, [bannerNotifications]);

	useEffect(() => {
		if (BannerNotifications.length && isTrial) {
			const {
				createdAt,
				expiresAt,
				notification: { body, duration, coupon }
			} = BannerNotifications[0];
			dispatch(
				setBannerData({
					showBanner: true,
					bannerMessage: body,
					isTrial: true,
					isDismissible: false,
					duration,
					createdAt,
					expiresAt,
					takeOver: true,
					coupon
				})
			);
		}
	}, [BannerNotifications, dispatch]);

	return (
		<SharedTopNav
			userInfo={userInfo}
			banner={bannerData?.showBanner ? <Banner bannerData={bannerData} /> : undefined}
			accountPath={routes.myAccount.getPath()}
			onLogOut={async () => {
				dispatch(logout());
				await onDeleteFcmToken();
				analytics({ eventName: "logout" });
			}}
			topMenuItems={
				<>
					{/*	<MessageIcon>
						<Icon name="message-linear" height={19} width={22} fill="#2a2e37" />
						</MessageIcon>
					*/}
					<BellNotifications />
					<Tooltip title="FAQ">
						<IconButton
							size="small"
							onClick={() => {
								if (location.pathname === routes.help.getPath()) history.go(0);
								else history.push(routes.help.getPath());
							}}
						>
							<HelpRounded style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
						</IconButton>
					</Tooltip>
				</>
			}
		/>
	);
};

export default memo(TopNav);
