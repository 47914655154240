import React, { useEffect } from "react";

import { ScrollToTop } from "@remar/shared/dist/components/ScrollToTop";
import { isDesktop, isSafari } from "react-device-detect";
import TagManager from "react-gtm-module";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { fetchCountries } from "store/features/Auth/authSlice";
import { UserSubscriptionTypeCategories } from "store/features/Auth/constants";

import AccountClaim from "modules/Auth/AccountClaim";
import BookCheckOut from "modules/Auth/BookCheckOut";
import CourseSelect from "modules/Auth/CourseSelect";
import ForgotPassword from "modules/Auth/ForgotPassword";
import InvitationSignUp from "modules/Auth/InvitationSignUp";
import PaidSignUp from "modules/Auth/PaidSignUp";
import ResetPassword from "modules/Auth/ResetPassword";
import SignIn from "modules/Auth/Signin";
import SocialSignUp from "modules/Auth/SocialSignUp";
import SocialTrialSignUp from "modules/Auth/SocialTrialSignUp";
import TrialSignUp from "modules/Auth/TrialSignUp";
import Billings from "modules/Billings";
import BrowserAlert from "modules/BrowserAlert/BrowserAlert";
import ConfirmEmail from "modules/ConfirmEmail";
import Course from "modules/Course";
import NoAccess from "modules/Error/NoAccess";
import NotFound from "modules/Error/NotFound";
import FileVault from "modules/FileVault";
import FileVaultSubFolders from "modules/FileVault/FileVaultSubFolders";
import FileVaultSubFoldersFiles from "modules/FileVault/FileVaultSubFoldersFiles";
import Help from "modules/Help/help";
import { MaintenancePage } from "modules/Layout";
import AuthRoute from "modules/Layout/AuthRoute";
import GlobalWrapper from "modules/Layout/Wrappers/GlobalWrapper";
import Lesson from "modules/Lesson";
import MyAccount from "modules/MyAccount";
import DetailedCourseCompletion from "modules/MyAccount/DetailedCourseCompletion";
import ManageAccount from "modules/MyAccount/ManageAccount";
import Notes from "modules/Notes";
import QuestionBank from "modules/QuestionBank";
import CreateTest from "modules/QuestionBank/CreateTest";
import Test from "modules/QuestionBank/Test";
import TestHistory from "modules/QuestionBank/TestHistory";
import Tests from "modules/Tests";
import TakeTests from "modules/Tests/TakeTests";

import { routes } from "./constants";

import { GLOBAL_CONSTANTS } from "../constants";
import Assets from "../modules/Assets";

const PREFIX = "/";

const Routes = () => {
	const { isLoggedIn, subscriptionTypes, userSubscriptionTypeId, countries } = useAppSelector(
		({ auth }: RootState) => auth
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		TagManager.initialize({ gtmId: GLOBAL_CONSTANTS.GOOGLE_ANALYTICS_ID });
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			const currentActiveType = subscriptionTypes.find(item => item.id === userSubscriptionTypeId);
			let userTypeId: number = userSubscriptionTypeId;
			if (
				currentActiveType?.isTrial ||
				currentActiveType?.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategories.LocationPerSeat
			) {
				userTypeId = 0;
			} else if (currentActiveType?.isRecurring) {
				userTypeId = subscriptionTypes.find(item => item.nextSubscriptionTypeId === userSubscriptionTypeId)
					?.id as number;
			}
			if (!countries.length && currentActiveType) {
				dispatch(fetchCountries(userTypeId));
			}
		}
	}, [userSubscriptionTypeId, isLoggedIn, subscriptionTypes, countries, dispatch]);

	if (isSafari && isDesktop && GLOBAL_CONSTANTS.REACT_APP_SAFARI_DISABLED) {
		return <BrowserAlert />;
	}

	return (
		<BrowserRouter>
			<ScrollToTop />
			<GlobalWrapper>
				<Route path={PREFIX}>
					<Switch>
						<Route path="/AccessDenied" exact component={NoAccess} />
						<Route
							key={routes.maintenance.getElementKey()}
							path={routes.maintenance.getPath()}
							component={MaintenancePage}
						/>
						<AuthRoute exact path="/">
							<Redirect to={routes.course.getPath()} />
						</AuthRoute>
						<AuthRoute exact key={routes.signIn.getElementKey()} path={routes.signIn.getPath()}>
							<SignIn />
						</AuthRoute>
						<AuthRoute exact key={routes.signUp.getElementKey()} path={`${routes.signUp.getPath()}/course`}>
							<CourseSelect />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.signUp.getElementKey()}
							path={`${routes.signUp.getPath()}/course/:courseId/trial`}
						>
							<TrialSignUp />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.signUp.getElementKey()}
							path={`${routes.signUp.getPath()}/oauth/course/:courseId/trial`}
						>
							<SocialTrialSignUp />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.signUp.getElementKey()}
							path={`${routes.signUp.getPath()}/course/:courseId/:subscriptionTypeId`}
						>
							<PaidSignUp />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.signUp.getElementKey()}
							path={`${routes.signUp.getPath()}/oauth/course/:courseId`}
						>
							<SocialSignUp />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.signUp.getElementKey()}
							path={`${routes.signUp.getPath()}/bookCheckout/:bookId`}
						>
							<BookCheckOut />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.signUp.getElementKey()}
							path={`${routes.signUp.getPath()}/course/:courseId/claimAccount/:accountClaimCode`}
						>
							<AccountClaim />
						</AuthRoute>
						<AuthRoute exact key={routes.course.getElementKey()} path={`${routes.course.getPath()}/:chapterId?`}>
							<Course />
						</AuthRoute>
						<AuthRoute exact key={routes.lesson.getElementKey()} path={`${routes.lesson.getPath()}/:id/:sectionId`}>
							<Lesson />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.lesson.getElementKey()}
							path={`${routes.lesson.getPath()}/:id/:sectionId/history`}
						>
							<TestHistory />
						</AuthRoute>
						<AuthRoute exact key={routes.questionBank.getElementKey()} path={routes.questionBank.getPath()}>
							<QuestionBank />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.questionBank.getElementKey()}
							path={`${routes.questionBank.getPath()}/createTest`}
						>
							<CreateTest />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.questionBank.getElementKey()}
							path={`${routes.questionBank.getPath()}/test/:id`}
						>
							<Test />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.questionBank.getElementKey()}
							path={`${routes.questionBank.getPath()}/test/:id/history`}
						>
							<TestHistory />
						</AuthRoute>
						<AuthRoute exact key={routes.myAccount.getElementKey()} path={routes.myAccount.getPath()}>
							<MyAccount />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.myAccount.getElementKey()}
							path={`${routes.myAccount.getPath()}/manageAccount`}
						>
							<ManageAccount />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.myAccount.getElementKey()}
							path={`${routes.myAccount.getPath()}/courseCompletion`}
						>
							<DetailedCourseCompletion />
						</AuthRoute>
						<AuthRoute exact key={routes.assets.getElementKey()} path={routes.assets.getPath()}>
							<Assets />
						</AuthRoute>
						<AuthRoute exact key={routes.fileVault.getElementKey()} path={routes.fileVault.getPath()}>
							<FileVault />
						</AuthRoute>
						<AuthRoute exact key={routes.notes.getElementKey()} path={routes.notes.getPath()}>
							<Notes />
						</AuthRoute>
						<AuthRoute exact key={routes.fileVault.getElementKey()} path={`${routes.fileVault.getPath()}/:folderId`}>
							<FileVaultSubFolders />
						</AuthRoute>
						<AuthRoute
							exact
							key={routes.fileVault.getElementKey()}
							path={`${routes.fileVault.getPath()}/:folderId/subFolders/:subFolderId/files`}
						>
							<FileVaultSubFoldersFiles />
						</AuthRoute>
						<AuthRoute exact key={routes.help.getElementKey()} path={routes.help.getPath()}>
							<Help />
						</AuthRoute>
						<AuthRoute exact key={routes.billings.getElementKey()} path={routes.billings.getPath()}>
							<Billings />
						</AuthRoute>
						<AuthRoute exact key={routes.tests.getElementKey()} path={routes.tests.getPath()}>
							<Tests />
						</AuthRoute>
						<AuthRoute exact key={routes.test.getElementKey()} path={`${routes.test.getPath()}/:id`}>
							<TakeTests />
						</AuthRoute>
						<AuthRoute
							restricted={false}
							exact
							key={routes.forgotPassword.getElementKey()}
							path={routes.forgotPassword.getPath()}
						>
							<ForgotPassword />
						</AuthRoute>
						<AuthRoute
							restricted={false}
							key={`${routes.create_password.getElementKey()}`}
							path={`${routes.create_password.getPath()}/:code`}
						>
							<ResetPassword />
						</AuthRoute>
						<Route key={`${routes.email_verification.getPath()}`} path={`${routes.email_verification.getPath()}/:code`}>
							<ConfirmEmail />
						</Route>
						<AuthRoute
							restricted={false}
							key={`${routes.email_invitation.getElementKey()}`}
							path={`${routes.email_invitation.getPath()}/:invitationId`}
						>
							<InvitationSignUp />
						</AuthRoute>
						<Route path="*">
							<NotFound />
						</Route>
					</Switch>
				</Route>
			</GlobalWrapper>
		</BrowserRouter>
	);
};

export default Routes;
