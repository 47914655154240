import React, { useEffect } from "react";

import { getFcmTokenFromStorage, setFcmTokenToStorage } from "@remar/shared/dist/api/fcm";
import { NotificationTypeId } from "@remar/shared/dist/models";
import useFirebaseNotifications from "@remar/shared/dist/services/useFirebaseNotifications";
import useNotificationSocketService from "@remar/shared/dist/services/useNotificationSocketService";

import { useAppDispatch, useAppSelector } from "store";
import { selectLogedIn, subscribeFirebaseNotifications } from "store/features/Auth/authSlice";
import { ISocketNotificationItem } from "store/features/notifications/notifications.model";
import { pushSocketNotifications } from "store/features/notifications/notifications.slice";

import SnackBarNotifications from "core/Notifications";

const NotificationsWrapper = ({ children }) => {
	const dispatch = useAppDispatch();
	const isLoggedIn = useAppSelector(selectLogedIn);
	const { subscribeNotifications, terminateSocketConnection } = useNotificationSocketService();
	const fcmToken = getFcmTokenFromStorage();
	const { getFcmToken, onMessageListener } = useFirebaseNotifications();

	// firebase push notification connection
	useEffect(() => {
		if (isLoggedIn) {
			if (fcmToken) {
				dispatch(subscribeFirebaseNotifications(fcmToken));
				onMessageListener(({ title, body }) =>
					dispatch(
						pushSocketNotifications({
							notification: {
								title,
								body,
								deliveryMethod: NotificationTypeId.AdminGeneratedPush
							}
						})
					)
				);
			} else {
				getFcmToken(setFcmTokenToStorage).then(() => {
					dispatch(subscribeFirebaseNotifications(getFcmTokenFromStorage() as string));
					onMessageListener(({ title, body }) =>
						dispatch(
							pushSocketNotifications({
								notification: {
									title,
									body,
									deliveryMethod: NotificationTypeId.AdminGeneratedPush
								}
							})
						)
					);
				});
			}
		}
	}, [dispatch, fcmToken, getFcmToken, isLoggedIn, onMessageListener]);

	// ws connection
	useEffect(() => {
		subscribeNotifications("notification", (data: ISocketNotificationItem) => dispatch(pushSocketNotifications(data)));

		return () => {
			terminateSocketConnection(["notification"]);
		};
	}, [dispatch, subscribeNotifications, terminateSocketConnection]);
	return (
		<>
			{children}
			<SnackBarNotifications />
		</>
	);
};

export default NotificationsWrapper;
