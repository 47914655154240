import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { Box, Typography } from "@material-ui/core";
import { Assessment, Visibility } from "@material-ui/icons";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { ReactComponent as DiscountIcon } from "@remar/shared/dist/assets/icons/icon-coupon-multicolour-light.svg";
import Button from "@remar/shared/dist/components/Button";
import { LessonTypes } from "@remar/shared/dist/constants";
import { CourseChapterSection, CourseChapterSectionLesson } from "@remar/shared/dist/models";
import { getMinutesAndSeconds } from "@remar/shared/dist/utils/getMinutesAndSeconds";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import cn from "classnames";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { values } from "lodash";

import { Link } from "react-router-dom";

import { RootState, useAppDispatch, useAppSelector } from "store";
import { applyValidatedCoupon, selectInactiveSubscription, setUpgradeModal } from "store/features/Auth/authSlice";
import { selectMuted, setMuted } from "store/features/Course/courseSlice";
import { selectSubscriptionInfo } from "store/features/MyAccount/myAccountSlice";
import { getFullState as getFullNotificationsState } from "store/features/notifications/notifications.slice";

import videojs from "video.js";

import { routes } from "core/constants";

import "video.js/dist/video-js.min.css";

import { LessonThumbnailMuteIcon, OverflowHide, StyledCrownSvg, StyledLessonLockSvg, useStyles } from "./style";

interface Props {
	sectionLesson: CourseChapterSectionLesson;
	latestLesson: CourseChapterSectionLesson;
	section: CourseChapterSection;
	number: number;
}
const UpgradeRenewComponent = ({ text }) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();
	const { bannerData } = useAppSelector(getFullNotificationsState);

	return (
		<>
			<Button
				className={classes.upgradeButton}
				color="primary"
				variant="contained"
				size="small"
				onClick={() => {
					if (bannerData?.coupon) {
						dispatch(applyValidatedCoupon(bannerData?.coupon));
					}
					dispatch(setUpgradeModal(true));
				}}
			>
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
					{!!bannerData?.coupon && (
						<Box display={"flex"} mr={1}>
							<DiscountIcon />
						</Box>
					)}
					{text}
				</Box>
			</Button>
		</>
	);
};

const CourseLessonItem: FC<Props> = ({ sectionLesson, section, latestLesson }) => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const videoRef = useRef<HTMLVideoElement>(null);
	const videoPlayer = useRef<videojs.Player>();

	const state: RootState = useAppSelector((state: RootState) => state);

	const [videoReadyToPlay, setVideoReadyToPlay] = useState(false);
	const [playersToPauseResume, setPlayersToPauseResume] = useState<videojs.Player[]>([]);
	const analytics = useAnalyticsEventTracker("Lesson");
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);
	const subscriptionInfo = useAppSelector(selectSubscriptionInfo);
	const { subscription } = state.auth;

	const muted = useAppSelector(selectMuted);

	const { lesson, isLockedByPayment, isLockedForCurrentUser } = sectionLesson;
	const { mainImageUrl, mainImageKey, trailer, mainImageThumbnailUrl } = lesson!;

	const { mainImageUrl: sectionMainImageUrl, mainImageThumbnailUrl: sectionMainThumbnailUrl } = section;

	const canPlayThrough = () => {
		setVideoReadyToPlay(true);
	};

	const loadPlayer = () => {
		if (trailer) {
			videoPlayer.current = getVideoPlayer(videoRef!.current!, trailer.videoUrl, {
				muted,
				loop: true,
				controls: false,
				autoplay: "play"
			});

			videoRef.current?.addEventListener("canplaythrough", canPlayThrough);
			if (videoRef.current) {
				//Required for safari support
				videoPlayer.current?.playsinline(true);
			}
		}
	};
	useEffect(() => {
		if (trailer && videoPlayer) {
			return () => {
				videoRef.current?.removeEventListener("canplaythrough", canPlayThrough);
				videoPlayer.current?.dispose();
				// now starting paused players
				playersToPauseResume.forEach(player => player.play());
			};
		}
	}, [playersToPauseResume, trailer]);

	const playVideo = videoPlayer => {
		if (trailer) {
			videoPlayer.current?.currentTime(0);

			// before start playing we should pause all other players like next lesson banner, so pausing all unpaused players
			// and starting them again after trailer disappears
			const players = values(videojs.getPlayers())
				// do not replace code "player && !player.paused()" with "!player?.paused()" we do not need undefined player values here
				.filter(player => player && !player.paused());

			setPlayersToPauseResume(players);
			players.forEach(player => player.pause());

			videoPlayer.current?.play();
		}
	};

	const stopVideo = () => {
		videoPlayer.current?.pause();

		playersToPauseResume.forEach(player => player.play());
	};

	const totalTime = (lesson?.manualTimeLengthComponent ?? lesson?.totalTimeLengthInMinutes) || 0;
	const { minutes, seconds } = useMemo(() => {
		return getMinutesAndSeconds(totalTime);
	}, [totalTime]);

	return (
		<Box className={classes.root}>
			<div className={classes.extraWrapper}>
				{isLockedForCurrentUser &&
					(isLockedByPayment ? (
						<StyledCrownSvg width={22} height={22} className={classes.icon} />
					) : (
						<StyledLessonLockSvg width={22} height={22} className={classes.icon} />
					))}
				<Box borderRadius={4} className={classes.expandable}>
					<Link
						to={`${routes.lesson.getPath()}/${sectionLesson.lessonId}/${sectionLesson.sectionId}`}
						onMouseEnter={() => {
							videoPlayer ? playVideo(videoPlayer) : loadPlayer();
						}}
						onMouseLeave={stopVideo}
					>
						<OverflowHide height={183}>
							<img
								className={cn(classes.bgImage, {
									[classes.hidden]: videoReadyToPlay
								})}
								src={
									(mainImageKey
										? mainImageThumbnailUrl?.small250 || mainImageUrl
										: sectionMainThumbnailUrl?.small250 || sectionMainImageUrl) || "https://picsum.photos/200"
								}
								alt={`${lesson!.name} preview image`}
							/>
							{trailer && (
								<video
									ref={videoRef}
									className={cn(classes.videoThumbnail, {
										[classes.videoReadyToPlay]: videoReadyToPlay
									})}
								/>
							)}
						</OverflowHide>
						<Box className="bottomPanel">
							<Box p={2} display="flex" flexDirection="column" justifyContent="flex-end">
								<Typography className={classes.bottomTitle}>{lesson!.name}</Typography>
								<Typography className={classes.bottomSubTitle}>{lesson!.description}</Typography>
								<Box display="flex" alignItems="center" mt={2}>
									{(lesson!.totalTimeLengthInMinutes || lesson!.manualTimeLengthComponent) && (
										<Box display="flex" alignItems="center">
											<Visibility fontSize={"small"} color="disabled" />
											<Typography className={classes.bottomLabel}>{minutes} min</Typography>
											<Typography className={classes.bottomLabel}>{seconds} sec</Typography>
										</Box>
									)}

									{(lesson!.typeId === LessonTypes.TestOnly || lesson!.typeId === LessonTypes.Interactive) && (
										<Box ml={2} display="flex" alignItems="center">
											<Assessment fontSize={"small"} color="disabled" />
											<Typography className={classes.bottomLabel}>
												{lesson!.typeId === LessonTypes.TestOnly ? "Contains test" : "Interactive Training"}
											</Typography>
										</Box>
									)}
									{trailer && (
										<LessonThumbnailMuteIcon
											onClick={e => {
												dispatch(setMuted(!muted));
												if (videoPlayer) {
													videoPlayer.current?.muted(!muted);
												}
												e.stopPropagation();
												e.preventDefault();
											}}
										>
											{!muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
										</LessonThumbnailMuteIcon>
									)}
								</Box>
							</Box>
						</Box>
					</Link>
				</Box>
				{isLockedByPayment && subscription.isTrial && !isRenewEnabled ? (
					<UpgradeRenewComponent text={"Upgrade Subscription"} />
				) : (
					(subscriptionInfo?.subscription.isCancelled || isRenewEnabled) && (
						<UpgradeRenewComponent text={"Renew Subscription"} />
					)
				)}
				{!isLockedByPayment && isLockedForCurrentUser ? (
					<div className={classes.previousLesson}>
						<ChevronLeft />
						<Link
							className={classes.previousLessonLink}
							to={`${routes.lesson.getPath()}/${latestLesson?.lessonId}/${latestLesson?.sectionId}`}
							onClick={() => {
								analytics({ eventName: "click_locked_lesson", eventIdentifier: `${latestLesson?.lessonId}` });
							}}
						>
							Complete Previous Lesson
						</Link>
					</div>
				) : null}
			</div>
		</Box>
	);
};

export default CourseLessonItem;
