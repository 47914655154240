import React, { useEffect, useMemo } from "react";

import { Box, IconButton, Link, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTimeLeftCountDown } from "@remar/shared/dist/hooks/useTimeLeftCountDown";
import clsx from "clsx";
import { addMinutes, isAfter } from "date-fns";

import { useAppDispatch, useAppSelector } from "store";
import {
	applyValidatedCoupon,
	hideBannerOnCancelSub,
	selectInactiveSubscription,
	selectIsTrial,
	setStateValue,
	setUpgradeModal
} from "store/features/Auth/authSlice";
import { IBannerData } from "store/features/notifications/notifications.model";
import { setBannerData } from "store/features/notifications/notifications.slice";

import { BannerContainer, useStyles } from "./styles";

import { GLOBAL_CONSTANTS } from "../../../constants";

interface ISaleBanner {
	startDate: string;
	endDate: string;
	copy: string;
	background: string;
}

type SaleBannerProps = Pick<ISaleBanner, "copy">;
const SaleBanner = ({ copy }: SaleBannerProps) => <Box>{decodeURIComponent(atob(copy))}</Box>;

const Banner = ({ bannerData }: { bannerData: IBannerData }) => {
	const { bannerMessage, duration, createdAt, expiresAt, isDismissible = true, takeOver = false, coupon } = bannerData;
	const theme = useTheme();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useAppDispatch();

	const trial = useAppSelector(selectIsTrial);
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);

	const { onStart, timeLeft, isStarted } = useTimeLeftCountDown();

	useEffect(() => {
		if (duration && createdAt) {
			const { days = 0, hours = 0, minutes = 0 } = duration;
			const daysToMinutes = days * 24 * 60;
			const hoursToMinutes = hours * 60;
			const totalMinutes = daysToMinutes + hoursToMinutes + minutes;
			onStart(addMinutes(new Date(createdAt), totalMinutes));
		}
	}, [duration, createdAt]);

	const saleBannersToShow = useMemo(() => {
		const { SALE_BANNERS } = GLOBAL_CONSTANTS;
		return SALE_BANNERS
			? (JSON.parse(SALE_BANNERS) as Array<ISaleBanner>).filter(({ endDate, startDate }) => {
					const now = new Date();
					return new Date(startDate) < now && new Date(endDate) > now;
			  })
			: [];
	}, []);

	const canApplyCoupon = useMemo(
		() => isStarted || (expiresAt && isAfter(new Date(expiresAt), new Date())),
		[isStarted, expiresAt]
	);
	const bannerMessageText = useMemo(() => {
		if (trial && !isRenewEnabled) {
			if (saleBannersToShow.length) {
				return <SaleBanner {...saleBannersToShow[0]} />;
			}
			if (takeOver && canApplyCoupon) {
				return bannerMessage;
			}
			return (
				<>
					{!isMobile && "Click"}
					<Link className={classes.manageSubscriptionBtn}>
						{isMobile ? "Trial mode. Upgrade Subscription" : "Here"}
					</Link>
					{!isMobile && "to buy now"}
				</>
			);
		}
		return bannerMessage;
	}, [trial, isRenewEnabled, saleBannersToShow, isMobile, bannerMessage, canApplyCoupon]);

	const showCloseIcon = useMemo(() => !trial, [trial]);

	return (
		<>
			<Box
				onClick={() => {
					if (!!coupon && canApplyCoupon) {
						dispatch(applyValidatedCoupon(coupon));
					} else {
						dispatch(setStateValue({ key: "validatedCoupon", value: null }));
					}
					dispatch(setUpgradeModal(true));
				}}
				bgcolor={
					trial && !isRenewEnabled && saleBannersToShow.length && saleBannersToShow[0].background + "! important"
				}
				className={clsx(classes.defaultNotice, trial && !isRenewEnabled && classes.trialNotice)}
			>
				<Box display={"flex"} alignItems={"center"}>
					<BannerContainer>
						<Typography className={clsx(classes.bannerMessage, trial && !isRenewEnabled && classes.trialText)}>
							{bannerMessageText}
						</Typography>
					</BannerContainer>
					<Typography style={{ marginLeft: "10px" }} className={classes.trialText} align={"center"}>
						{timeLeft}
					</Typography>
				</Box>
				{showCloseIcon && isDismissible && (
					<IconButton
						size="small"
						className={classes.closeNotice}
						onClick={() => {
							dispatch(setBannerData({ showBanner: false }));
							dispatch(hideBannerOnCancelSub());
						}}
					>
						<Close />
					</IconButton>
				)}
			</Box>
		</>
	);
};

export default Banner;
